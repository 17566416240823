<template>
  <!--begin::User-->
  <div>
    <h3 class="card-label">
      {{ $t('MENU.invoices_bonds_management') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>

    <div class="row mt-5">
      <div class="col-md-12">

        <div class="bg-white">
          <table class="table table-row-bordered table-custom-padding">
            <thead>
            <tr class="text-center">
              <th>{{ $t('invoices_bonds_management.invoice_bond') }}</th>
              <th>{{ $t('invoices_bonds_management.customer_credit') }}</th>
              <th>{{ $t('invoices_bonds_management.customer_debit') }}</th>
              <th>{{ $t('invoices_bonds_management.supplier_credit') }}</th>
              <th>{{ $t('invoices_bonds_management.supplier_debit') }}</th>
              <th>{{ $t('status') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in invoice_bonds" :key="index">
              <td><span style="padding: 10px;display: block;">{{row.invoice_bond_name}}</span></td>
              <td>

                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.min') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.customer_minimum_credit">
                  </div>
                </div>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.max') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.customer_maximum_credit">
                  </div>
                </div>
              </td>
              <td>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.min') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.customer_minimum_debit">
                  </div>
                </div>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.max') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.customer_maximum_debit">
                  </div>
                </div>
              </td>
              <td>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.min') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.supplier_minimum_credit">
                  </div>
                </div>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.max') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.supplier_maximum_credit">
                  </div>
                </div>
              </td>
              <td>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.min') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.supplier_minimum_debit">
                  </div>
                </div>
                <div class="m-4 row">
                  <label class="col-sm-3 col-form-label">{{ $t('invoices_bonds_management.max') }}</label>
                  <div class="col-sm-9">
                    <input type="number" class="form-control" v-model="row.supplier_maximum_debit">
                  </div>
                </div>
              </td>
<!--              <td>-->
<!--                <b-form-checkbox :id="'credit'+index" v-model="row.is_credit" :name="'credit'+index"></b-form-checkbox>-->
<!--              </td>-->
<!--              <td>-->
<!--                <b-form-checkbox :id="'debit'+index" v-model="row.is_debit" :name="'debit'+index"></b-form-checkbox>-->
<!--              </td>-->
              <td>
                <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
              </td>

            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

    <div class="pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button type="reset" class="btn btn-primary mr-2" @click="save()">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--end::User-->
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Form",
  data(){
    return {
      mainRoute: 'invoices_bonds_management',
      mainUserRoute: 'users',
      mainRouteDependency: 'base/dependency',
      user_id: this.$route.params.user_id ? this.$route.params.user_id : null,
      invoice_bonds: [],
      validation: null,
    }
  },

  methods:{
    save() {
      this.update();
    },

    update() {
      if (this.user_id)
        ApiService.put(this.mainRoute + '/' + this.user_id, {
          invoice_bonds: this.invoice_bonds,
        }).then((response) => {
          this.$router.push({name: 'users.index'})
          this.validation = null;
          this.$successAlert(response.data.message);
        }).catch((error) => {
              this.$errorAlert(error);
              this.validation = error.response ? error.response.data.errors : null;
            });
    },

    async getData() {
      if (this.user_id)
      await ApiService.get(this.mainUserRoute + '/' + this.user_id).then((response) => {
        this.invoice_bonds = response.data.data.invoice_bonds.map((row)=>{
          return {
            slug: row.slug,
            // is_debit: row.is_debit,
            // is_credit: row.is_credit,
            is_active: row.is_active,
            invoice_bond_id: row.invoice_bond_id,
            invoice_bond_name: row.invoice_bond_name,
            customer_minimum_credit: row.customer_minimum_credit,
            customer_maximum_credit: row.customer_maximum_credit,
            customer_minimum_debit: row.customer_minimum_debit,
            customer_maximum_debit: row.customer_maximum_debit,
            supplier_minimum_credit: row.supplier_minimum_credit,
            supplier_maximum_credit: row.supplier_maximum_credit,
            supplier_minimum_debit: row.supplier_minimum_debit,
            supplier_maximum_debit: row.supplier_maximum_debit,

        }
        });
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("MENU.user_Management"),route:'/users/users'},
      {title: this.$t("MENU.invoices_bonds_management")},
    ]);

    if (this.user_id) {
      this.getData();
    }

  },
}
</script>

<style scoped>

</style>